<template>
  <div class="link">
    <div class="content">
      <div class="title">连接/二维码</div>
      <div class="share">分享方式</div>
      <div class="box">
        <div class="linkShare">
          <div class="linkTitle">链接分享</div>
          <div class="input">
            <el-input v-model="input" :disabled="true"> </el-input>
          </div>
          <div class="open">
            <div @click="dialogVisible = true">打开</div>
          </div>
        </div>
        <div class="wxShare">
          <div class="wxTitle">微信扫码分享</div>
          <div class="input">
            <div class="bookcode" id="qrcode" ref="qrcode1"></div>
          </div>
          <div class="download">
            <div @click="download">下载</div>
            <span> | </span>
            <div @click="setPoster">生成海报</div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="rightTitle">分享设置</div>
      <div class="rightWho">谁可以填答</div>
      <el-select v-model="val" class="select">
        <el-option :value="1" label="所有人" />
        <el-option :value="2" label="联系人" />
        <el-option :value="3" label="录入名单" />
        <el-option :value="4" label="选择评分人" />
      </el-select>
      <div class="rightQuick">快捷功能</div>
      <div class="rightRemind">
        <div>接收回收提醒</div>
        <el-switch v-model="flag" active-color="#008755"> </el-switch>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="50%"
      :modal-append-to-body="false"
    >
      <span>{{ input }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisiblePoster"
      width="50%"
      class="dialog"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      top='8vh'
    >
      <div class="img" ref="codeRef">
        <img src="@/assets/picNotit.png" alt="" />
        <div class="title">{{title}}</div>
        <div class="bookcode" id="qrcodeImg" ref="qrcode2"></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="downImg">下载海报</el-button>
        <el-button type="primary" @click="dialogVisiblePoster = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import apis from "@/apis";
import QRCode from "qrcodejs2";
import html2canvas from "html2canvas";
import Cookies from 'js-cookie'
export default {
  name: "link",
  data() {
    return {
      input: "",
      dialogVisible: false,
      dialogVisiblePoster: false,
      QRCodeMsg: "",
      val: 1,
      flag: true,
      posterHtmlBg: require("@/assets/picNotit.png"), // 背景图
      title:''
    };
  },
  mounted() {
    this.getShare();
    this.title = Cookies.get("title");
  },
  methods: {
    setPoster() {
      this.dialogVisiblePoster = true;
      this.$nextTick(() => {
        this.qrcodeImg();
      });
    },
    getShare() {
      apis.questionnaireShare({ id: this.$route.query.id }).then(({ data }) => {
        this.input = data;
        this.qrcode();
      });
    },
    qrcodeImg() {
      let long = document.documentElement.clientWidth*0.12;
      let qrcode = new QRCode("qrcodeImg", {
        width: long, //图像宽度
        height: long, //图像高度
        colorDark: "#000000", //前景色
        colorLight: "#ffffff", //背景色
        typeNumber: 4,
        correctLevel: QRCode.CorrectLevel.H,
      });
      qrcode.clear(); //清除二维码
      qrcode.makeCode(this.input);
    },
    qrcode() {
      let qrcode = new QRCode("qrcode", {
        width: 150, //图像宽度
        height: 150, //图像高度
        colorDark: "#000000", //前景色
        colorLight: "#ffffff", //背景色
        typeNumber: 4,
        correctLevel: QRCode.CorrectLevel.L,
      });
      qrcode.clear(); //清除二维码
      qrcode.makeCode(this.input);
    },
    download() {
      let qrcode = document.getElementById("qrcode");
      let img = qrcode.lastChild; // 获取img的dom节点
      const a = document.createElement("a"); // 创建a标签
      a.href = img.src;
      a.setAttribute("download", "二维码"); // 设置下载属性
      a.click();
    },
    downImg() {
      html2canvas(this.$refs.codeRef).then((canvas) => {
        // this.$refs.addImage.append(canvas);//在下面添加canvas节点
        let link = document.createElement("a");
        link.href = canvas.toDataURL(); //下载链接
        link.setAttribute("download", "海报.png");
        link.style.display = "none"; //a标签隐藏
        document.body.appendChild(link);
        link.click();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  width: 100%;
  height: 100%;
  display: flex;
  .content {
    flex: 1;
    background: #fff;
    margin-right: 10px;
    padding: 15px;
    .title {
      font-size: 14px;
    }
    .share {
      font-size: 13px;
      padding: 20px 0 20px 0;
    }
  }
  .right {
    height: 100%;
    width: 20%;
    background: #fff;
    padding: 20px 15px;
    .rightTitle {
      font-size: 12px;
      border-bottom: 2px solid #ccc;
      padding-bottom: 15px;
    }
    .rightWho {
      font-size: 12px;
      margin-top: 15px;
    }
    .rightQuick {
      font-size: 12px;
      margin-top: 15px;
    }
    .rightRemind {
      font-size: 11px;
      padding-top: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.box {
  display: flex;
  justify-content: space-around;
}
.linkShare {
  width: 45%;
  height: 200px;
  border: 1px solid #ccc;
  .linkTitle {
    display: flex;
    justify-content: center;
    font-size: 12px;
    padding: 30px 0 0 0;
  }
  .input {
    display: flex;
    justify-content: center;
    padding: 45px 0;
    ::v-deep .el-input {
      width: 90%;
    }
  }
  .open {
    display: flex;
    justify-content: center;
    color: #008755;
    font-size: 12px;
    span {
      margin: 0 25px;
    }
  }
}
.wxShare {
  width: 45%;
  height: 200px;
  border: 1px solid #ccc;
  .wxTitle {
    display: flex;
    justify-content: center;
    font-size: 12px;
    padding: 30px 0 0 0;
  }
  .input {
    display: flex;
    justify-content: center;
    padding: 16px 0;
    ::v-deep .el-input {
      width: 90%;
    }
  }
  .download {
    display: flex;
    justify-content: center;
    color: #008755;
    font-size: 12px;
    span {
      margin: 0 25px;
    }
  }
}
.img {
  width: 300px;
  display: flex;
  justify-content: center;
  img {
    width: 100%;
  }
  .title {
    color: #fff;
    font-size: 16px;
    position: absolute;
    top: 23%;
  }
}
.dialog {
  .box {
    display: flex;
  }
  #qrcodeImg {
    position: absolute;
    top: 38%;
  }
  ::v-deep .el-dialog__body{
    display: flex;
    justify-content: center;
  }
}
.select {
  margin-top: 15px;
  ::v-deep .el-input {
    width: 14vw;
  }
}
#qrcode {
  ::v-deep img{
    width: 80px;
    height: 80px;
  }
}
</style>
